import {IconButton} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Add, Close, Edit, FileCopy, Timer} from "@material-ui/icons";
import clsx from "clsx";

export const useBlockStyles = makeStyles({
  btn: {
    width: "auto",
    height: 38,
    borderRadius: 4,
    color: "#fff",
    cursor: "pointer",
    padding: "5px 12px",
    whiteSpace: "nowrap",
    backgroundColor: "#1375D1",
    minWidth: "auto",
    border: "none",

    "&:hover": {
      backgroundColor: "#1375D1"
    }
  },
  headRow: {
    backgroundColor: "#ECEEFD"
  },
  headRowHeader: {
    padding: "0"
  },
  headRowCell: {
    padding: "7px"
  },
  headBtn: {
    backgroundColor: "#3e63c1 !important",
    width: "auto",
    padding: "4px 5px",
    fontSize: "13px",
    height: "auto",
    "&:hover": {
      backgroundColor: "#3e63c1 !important"
    },
    "& svg": {
      marginLeft: "5px"
    }
  },
  iconButton: {
    padding: 5
  },
  zeroIconButton: {
    padding: 5
  },
  copyIcon: {
    width: 16,
    fill: "#205f8d"
  },
  deleteIcon: {
    fontSize: 18,
    fill: "#a40101"
  },
  editIcon: {
    fontSize: 18,
    fill: "#b66237"
  },
  addActionIcon: {
    fontSize: 18
  },
  disabled: {
    color: "#ccc",
    fill: "#ccc",
    cursor: "default"
  },
  filters: {
    display: "flex",
    alignItems: "center",
    gap: 15,
    flexWrap: "wrap"
  }
});

export const EditIconBtn = ({
                              onClick = () => {
                              }, title = ''
                            }) => {
  const classes = useBlockStyles();
  return (
    <IconButton title={title} className={classes.iconButton} onClick={onClick}>
      <Edit className={classes.editIcon}/>
    </IconButton>
  );
};
export const RemoveIconBtn = ({
                                onClick = () => {
                                }
                              }) => {
  const classes = useBlockStyles();
  return (
    <IconButton className={classes.iconButton} onClick={onClick}>
      <Close className={classes.deleteIcon}/>
    </IconButton>
  );
};
export const AddIconBtn = ({
                             onClick = () => {
                             }
                           }) => {
  const classes = useBlockStyles();
  return (<IconButton
      className={classes.iconButton}
      onClick={onClick}
    >
      <Add className={classes.addActionIcon}/>
    </IconButton>
  );
};
export const TimerIconBtn = ({
                               onClick = () => {
                               }
                             }) => {
  const classes = useBlockStyles();
  return (<IconButton
      className={classes.iconButton}
      onClick={onClick}
    >
      <Timer className={classes.addActionIcon}/>
    </IconButton>
  );
};

type CopyIconBtnType = {
  onClick?: () => void,
  disabled?: boolean,
  zeroPadding?: boolean,
  title?: string
}
export const CopyIconBtn = ({
                              onClick = () => {
                              }, disabled = false, zeroPadding = false, ...other
                            }: CopyIconBtnType) => {
  const classes = useBlockStyles();
  return (
    <IconButton
      disabled={disabled}
      className={clsx(classes.iconButton, {[classes.zeroIconButton]: zeroPadding})}
      onClick={onClick}
      {...other}
    >
      <FileCopy className={classes.copyIcon}/>
    </IconButton>
  );
};



