import React from "react";
import { Tab, Tabs } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  tab_root: {
    color: "#000",
    fontSize: "14px",
    textTransform: "capitalize",
    minWidth: "auto"
  }
});

type CustomTabsType = {
  value: string | number
  onChange: (e: any, id: number | string, key: string) => void
  tabs: any[]
  disabled?: boolean
}
export const CustomTabs = ({
  value,
  onChange,
  tabs = [],
  disabled = false
}: CustomTabsType) => {
  const classes = useStyles();
  return (
    <Tabs
      value={value}
      variant="scrollable"
      disabled={disabled}
      scrollButtons="auto"
      onChange={(e, id) => {
        const selected = tabs[id];
        return onChange && onChange(e, id, selected?.key);
      }}
      aria-label="scrollable auto tabs example"
      indicatorColor="primary"
      textColor="primary"
    >
      {tabs?.map((item, i) => {
        return (
          <Tab
            classes={{
              root: classes.tab_root
            }}
            key={i}
            label={item?.label || ""}
          />
        );
      })}
    </Tabs>
  );
};

export const TabPanel = props => {
  const {
    children,
    value,
    index,
    padding,
    background = "none",
    ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ padding: padding ? padding : 20, background }}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

export const CustomTabPanel = ({ value, children, index }) => {
  return (
    <TabPanel value={value} index={index} padding={"0"}>
      {children}
    </TabPanel>
  );
};

